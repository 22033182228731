import React, { useState } from 'react';
import './MyContactForm.css';
import './InterestedForm.css';
import { sendEmail } from "../api/api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const InterestedForm = (props) => {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [nameButton, setNameButton] = useState('Enviar');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setNameButton('Enviando...');
    const emailData = {
      name: nombre,
      email: email,
      phone: telefono,
    }

    try {
      const response = await sendEmail(emailData);
      console.log('Email sent successfully', response);
      toast.success(response.message);
    } catch (error) {
      console.error('Error sending email', error);
      toast.error(error.message);
    }
    setNombre('');
    setEmail('');
    setTelefono('');
    setDisabled(false);
    setNameButton('Enviado');
  };




  const toggleModal = () => {

    setShowModal(!showModal);
  };

  return (
    <>
      <button
        onClick={toggleModal}
        className="interested-button"
      >
        contactenme
      </button>
      {showModal && (
        <div className="modal-background" onClick={toggleModal}>
          <ToastContainer />
          <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            <button className='close' onClick={toggleModal}>X</button>

            <form onSubmit={handleSubmit}>
              <h1>Quiero que me contacten</h1>
              <div className="input-group">
                <label htmlFor="nombre">Nombre</label>
                <input
                  id="nombre"
                  type="text"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  placeholder="Escriba su nombre aquí"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Escriba su email aquí"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="telefono">Teléfono</label>
                <input
                  id="telefono"
                  type="tel"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  placeholder="Escriba su teléfono aquí"
                  required
                />
              </div>
              <div className="frame-div">
                <b className="usd-16950-uf2">$11.950 UF</b>
                <div className="precio-de-venta1">$ 464.007.267.00 CLP</div>
              </div>
              <button disabled={disabled} type="submit">{nameButton}</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}


export default InterestedForm;
