import Header from "../components/Header";
import FormContainer from "../components/FormContainer";
import FaqCard from "../components/FaqCard";
import ContactForm from "../components/ContactForm";
import SocialMediaContainer from "../components/SocialMediaContainer";
import Footer from "../components/Footer";
import StyleFilledStateenabledS from "../components/StyleFilledStateenabledS";
import Faqs from "../components/Faqs";
import ContactFormContainer from "../components/ContactFormContainer";
import ContactForm1 from "../components/ContactForm1";
import SizeMediumStateEnabledH from "../components/SizeMediumStateEnabledH";
import StatePrimaryContentLabel from "../components/StatePrimaryContentLabel";
import 'react-slideshow-image/dist/styles.css'
import "./Home.css";
import "./Home2.css";

import Slider from "react-slick";
import MyContactForm from "../components/MyContactForm";
import ImageSlider from "../components/ImagesSlider";
import InterestedForm from "../components/InterestedForm";
import OfferForm from "../components/OfferForm";


const importAll = (r) => r.keys().map(r);

const images = importAll(require.context('../imgs', false));

const Home = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "black", borderRadius: "50%" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "black", borderRadius: "50%" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="home">
      <div className="all-content">
        <Header />
        <div className="banner">
          <div>
            <img
              className="ios-1-icon"
              alt=""
              src="/20231212-131550250-ios-1@2x.png"
            />
          </div>
          <div className="content">
            <div className="pill">
              <div className="venta">Venta</div>
            </div>
            <b className="hostal-en-calama">{`HOSTAL EN CALAMA `}</b>
          </div>
        </div>
        <div className="block31">
          <div className="montorecepcinubicacin1">
            <div className="descuento7">
              <div className="precio-de-venta1">PRECIO DE VENTA</div>
              <div className="usd-16950-uf-container">
                <b className="usd-16950-uf2">$11.950 UF</b>
                <div className="clp2">$ 464.007.267.00 CLP</div>
              </div>
            </div>
            <div className="cards2">
              <div className="envo4">
                <img className="marker-01-icon1" alt="" src="/marker01.svg" />
                <div className="brasilia-1836-y1">
                  Brasilia #1836 y Ramirez #2713, Calama, Chile
                </div>
              </div>
              <div className="envo4">
                <img
                  className="marker-01-icon1"
                  alt=""
                  src="/vuesaxlinear3square.svg"
                />
                <div className="brasilia-1836-y1">30 piezas individuales</div>
              </div>
              <div className="envo4">
                <img
                  className="marker-01-icon1"
                  alt=""
                  src="/vuesaxlineardrop.svg"
                />
                <div className="brasilia-1836-y1">
                  15 baños: 4 compartidos 11 baños privados
                </div>
              </div>
              <div className="envo4">
                <img
                  className="marker-01-icon1"
                  alt=""
                  src="/vuesaxlinearcar.svg"
                />
                <div className="brasilia-1836-y1">6 estacionamientos</div>
              </div>
              <div className="envo4">
                <img className="marker-01-icon1" alt="" src="/home02.svg" />
                <div className="brasilia-1836-y1">Terraza</div>
              </div>
              <div className="envo4">
                <img className="marker-01-icon1" alt="" src="/home04.svg" />
                <div className="brasilia-1836-y1">Hall de entrada</div>
              </div>
              <div className="envo4">
                <img className="descuento-inner" alt="" src="/group-30.svg" />
                <div className="brasilia-1836-y1">Sala de reuniones</div>
              </div>
              <div className="envo4">
                <img className="group-icon" alt="" src="/group-31.svg" />
                <div className="brasilia-1836-y1">Cocina semi industrial</div>
              </div>
              <div className="envo4">
                <img className="envo-inner" alt="" src="/group-32.svg" />
                <div className="brasilia-1836-y1">Comedor grande</div>
              </div>
              <div className="envo4">
                <img className="envo-child1" alt="" src="/group-33.svg" />
                <div className="brasilia-1836-y1">Lavandería</div>
              </div>
            </div>
            <div className="button-group">
              <InterestedForm />
              <OfferForm />
            </div>
          </div>
          <div className="content5">
            <div className="detalle-de-producto1">Detalle de producto</div>
            <div className="lorem-ipsum-dolor2">
              Propiedad esquina, cerca del centro de Calama, lista para remodelar e instalar un Hostal. Cuenta con una amplia cantidad de habitaciones y baños, para tener a más de 30 familias/huéspedes. Mantiene áreas comunes, tal como, hall de entrada (área de recepción), sala de reuniones, comedor, lavandería, cocina, terraza, gimnasio, etc.

              La propiedad cuenta con 3 pisos de altura, el cual para acceder a ellos, tiene 3 escaleras ubicadas a lo largo del terreno, con capacidad para seguir ampliando el 3 piso. Solo tiene acceso por Ramirez, el cual tiene estacionamiento adentro de la propiedad, para uno 6-7 autos.

              Esta Propiedad se encuentra en un barrio tranquilo, al lado de una iglesia, el cual funciona los domingos.

              Tiene acceso a luz, agua y factibilidad para instalar internet o cable con una gran gama de proveedores de la zona
            </div>

          </div>
          <div className="galera1">
            <ImageSlider images={images} />

            <div className="button-group">
              <InterestedForm />
              <OfferForm />
            </div>
          </div>
        </div>
        <MyContactForm />
        <SocialMediaContainer home05="/home05.svg" />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
